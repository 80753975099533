<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-card-title>{{ YNghiaCacConSoData.id !== '' ? `Cập nhật hồ sơ người dùng ${YNghiaCacConSoData.hoten}` : '' }}</b-card-title>
      </b-card-header>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <!-- Tên đăng nhập -->
              <validation-provider
                #default="validationContext"
                name="Tên đăng nhập"
                rules="required"
              >
                <b-form-group
                  label="Tên đăng nhập"
                  label-for="username-nguoi-dung"
                >
                  <b-form-input
                    id="username-nguoi-dung"
                    v-model="YNghiaCacConSoData.username"
                    disabled="disabled"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <!-- Mật khẩu -->
              <validation-provider
                #default="validationContext"
                name="Mật khẩu"
                rules="required"
              >
                <b-form-group
                  label="Mật khẩu"
                  label-for="mat_khau"
                >
                  <b-form-input
                    id="mat_khau"
                    v-model="YNghiaCacConSoData.password_hash"
                    type="password"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Mật khẩu đăng nhập"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <!-- Họ tên -->
              <validation-provider
                #default="validationContext"
                name="Họ tên"
                rules="required"
              >
                <b-form-group
                  label="Họ tên"
                  label-for="ho-ten-nguoi-dung"
                >
                  <b-form-input
                    id="ho-ten-nguoi-dung"
                    v-model="YNghiaCacConSoData.hoten"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <!-- Ngày sinh -->
              <validation-provider
                #default="validationContext"
                name="Ngày sinh"
              >
                <b-form-group
                  label="Ngày sinh"
                  label-for="ngay-sinh"
                >
                  <flat-pickr
                    id="ngay-sinh"
                    v-model="YNghiaCacConSoData.ngay_sinh"
                    class="form-control"
                    :config="configDate"
                    placeholder="Chọn ngày sinh"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <!-- Điện thoại -->
              <validation-provider
                #default="validationContext"
                name="Điện thoại"
                rules="required"
              >
                <b-form-group
                  label="Điện thoại"
                  label-for="dien-thoai-nguoi-dung"
                >
                  <b-form-input
                    id="dien-thoai-nguoi-dung"
                    v-model="YNghiaCacConSoData.dien_thoai"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <!-- Email -->
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required"
              >
                <b-form-group
                  label="Email"
                  label-for="email-nguoi-dung"
                >
                  <b-form-input
                    id="email-nguoi-dung"
                    v-model="YNghiaCacConSoData.email"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <!-- Số lần xem còn lại -->
              <validation-provider
                #default="validationContext"
                name="Số bài còn lại"
                rules="required"
              >
                <b-form-group
                  label="Số bài còn lại"
                  label-for="so-lan-xem-con-lai"
                >
                  <b-form-input
                    id="so-lan-xem-con-lai"
                    v-model="YNghiaCacConSoData.so_lan_xem_con_lai"
                    disabled="disabled"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <!-- Địa chỉ -->
              <validation-provider
                #default="validationContext"
                name="Địa chỉ"
              >
                <b-form-group
                  label="Địa chỉ"
                  label-for="dia-chi-nguoi-dung"
                >
                  <b-form-input
                    id="dia-chi-nguoi-dung"
                    v-model="YNghiaCacConSoData.dia_chi"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              <feather-icon
                icon="CloudIcon"
                class="mr-50"
              />
              Lưu lại
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>

  </div>
</template>

<script>

import {
  BRow, BCol, BForm, BFormGroup, BFormInvalidFeedback, BFormInput,
  BCard, BCardTitle, BCardHeader, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import userStore from '../userStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardTitle,
    BCardHeader,
    BFormInput,
    BCard,
    BRow,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BCol,
    BButton,
  },
  data() {
    return {
      required,
      configDate: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
    }
  },
  setup() {
    const blankYNghiaCacConSoData = {
      id: '',
      username: '',
      password_hash: '',
      email: '',
      hoten: '',
      dien_thoai: '',
      dia_chi: '',
      ngay_sinh: '',
      cmnd: '',
      so_lan_xem_con_lai: 0,
    }

    const YNghiaCacConSoData = ref(JSON.parse(JSON.stringify(blankYNghiaCacConSoData)))

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    const resetuserData = () => {
      YNghiaCacConSoData.value = JSON.parse(JSON.stringify(blankYNghiaCacConSoData))
    }
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const currentUser = getUserData()
    const toast = useToast()
    const showToast = (variant, titleNotification, iconNotification, contentNotification) => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleNotification,
          icon: iconNotification,
          text: contentNotification,
          variant,
        },
      })
    }

    const onSubmit = () => {
      store.state.showBlock = true

      Object.assign(YNghiaCacConSoData.value, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
      })
      store.dispatch('app-user/updateProfile', YNghiaCacConSoData.value)
        .then(response => {
          store.state.showBlock = false
          showToast('success', 'Thông báo', 'BellIcon', response.data.content)
        })
        .catch(e => {
          if (typeof e.response !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          } else if (typeof e.message !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.message)
          } else {
            showToast('danger', 'Thông báo', 'BellIcon', e)
          }
          store.state.showBlock = false
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    store.state.showBlock = true

    store.dispatch('app-user/loadProfile', {
      id: router.currentRoute.params.id,
      auth: currentUser.auth_key,
      uid: currentUser.id,
    })
      .then(response => {
        YNghiaCacConSoData.value = response.data.user
        store.state.showBlock = false
      })
      .catch(error => {
        if (error.response.status === 404) {
          resetuserData()
        }
        this.showToast('danger', 'Thông báo', 'BellIcon', error)
        store.state.showBlock = false
      })

    return {
      onSubmit,
      YNghiaCacConSoData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
